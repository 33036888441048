<template>
  <b-card>
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Родительская категория"
            label-for="h-category-parent"
          >

            <b-form-select
              id="h-category-parent"
              v-model="categoryData.parent_id"
              :options="rootCategories"
              :text-field="'name'"
              :value-field="'id'"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Название категории"
            label-for="h-category-name"
          >
            <b-form-input
              id="h-category-name"
              v-model="categoryData.name"
              placeholder="Например: Игрушки фиксиков"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="URL Slug"
            label-for="h-category-slug"
          >
            <b-form-input
              id="h-category-slug"
              v-model="categoryData.slug"
              placeholder="Например: igrushki-fiksikov"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        @click="formSubmit"
      >
        {{ categoryData.id ? 'Update' : 'Create' }}
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BForm, BFormGroup, BFormSelect, BButton,
} from 'bootstrap-vue'

export default {
  name: 'CategoryForm',
  components: {
    BButton,
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormSelect,
  },
  props: ['categoryData', 'rootCategories'],
  methods: {
    formSubmit() {
      this.$emit('formSubmit', true)
    },
  },
}
</script>
